import { CONSTANTS } from '../constants'
import { setState, getState } from './storage'
import { isAuthInProgress, cruiseToDom } from './helpers'
import { RodeoJS } from './rodeojs' // Assumed RodeoJS is imported
import { log, error } from './logger'

/**
 * Key for storing the cruise profile in localStorage
 */
const STORAGE_PROFILE_KEY = CONSTANTS.STORAGE_KEYS.PROFILE

/**
 * Main logic to handle Cruise Profile
 */
export async function handleCruiseProfile() {
  // Skip fetching if a profile exists or auth is in progress
  if (hasCruiseProfile() || isAuthInProgress()) {
    log(CONSTANTS.LOG_MESSAGES.PROFILE_EXISTS_OR_AUTH)
    return
  }
  await fetchCruiseProfile()
}

/**
 * Fetch Cruise Profile from database
 */
async function fetchCruiseProfile() {
  try {
    const response = await RodeoJS.getCustomerCruiseProfile() // Fetch customer details
    const cruiseProfile = response?.cruise_profile

    if (cruiseProfile?.email) {
      // Save the profile to localStorage
      setState(STORAGE_PROFILE_KEY, cruiseProfile)

      // Update the global profile on the window object
      window.CURRENT_PROFILE = cruiseProfile
      window.cruise_profile = cruiseProfile
      cruiseToDom(cruiseProfile)

      log(CONSTANTS.LOG_MESSAGES.PROFILE_FETCH_SUCCESS, cruiseProfile)
    } else {
      // Save an empty fallback if email is missing
      setState(STORAGE_PROFILE_KEY, window.cruise_profile)
    }
  } catch (e) {
    error(CONSTANTS.MESSAGES.PROFILE_FETCH_ERROR, e)
  }
}

/**
 * Check if a valid Cruise Profile exists in localStorage
 * @returns {boolean} - True if a valid profile exists, otherwise false
 */
function hasCruiseProfile() {
  const profile = getState(STORAGE_PROFILE_KEY)

  if (!profile) {
    return false // No profile found
  }

  cruiseToDom(profile)

  try {
    return profile.email && profile.email.trim() !== ''
  } catch (e) {
    error(CONSTANTS.MESSAGES.PROFILE_PARSE_ERROR, e)
    return false
  }
}
