/**
 * Executes a script or task with retries and exponential backoff.
 *
 * @param {Array<string>} urls - Array of URLs or tasks to process.
 * @param {Function} executeScriptFunction - Function to execute each URL/task. Must return a Promise.
 * @param {Object} options - Optional configuration for retries and callbacks.
 * @param {number} [options.maxRetries=5] - Maximum number of retries allowed.
 * @param {number} [options.maxDelay=100] - Base delay in milliseconds for retries.
 * @param {number} [options.retryLimit=3000] - Maximum delay time in milliseconds.
 * @param {Function} [options.onSuccess] - Callback executed when a script/task succeeds.
 * @param {Function} [options.onError] - Callback executed when a script/task fails.
 * @returns {Promise<[any, Array]>} - Returns a promise resolving to the result and execution history.
 */
export function executeWithRetry(urls, executeScriptFunction, options = {}) {
  const { maxRetries = 5, maxDelay = 100, retryLimit = 3000, onSuccess = null, onError = null } = options

  // Initialize the script/task queue
  const scriptQueue = (function (urlList) {
    const queue = [...urlList]
    return {
      current: () => queue[0],
      postpone: () => {
        const url = queue.shift()
        if (url !== undefined) queue.push(url)
      },
      exclude: () => queue.shift(),
      isEmpty: () => queue.length === 0
    }
  })(urls)

  const resultSet = new Set() // To track unique retried URLs
  let attempts = 0 // Retry attempt counter

  const calculateDelay = () => Math.random() * Math.min(retryLimit, maxDelay * Math.pow(2, attempts++))

  const retryLogic = (startTime, error) => {
    const message = error instanceof Error ? error.message : ''
    const currentURL = scriptQueue.current()

    // Determine logic based on error type
    if (['Blocked by CSP', 'Invalid endpoint URL'].includes(message)) {
      scriptQueue.exclude()
    } else if (message === 'JS script load failed') {
      const elapsedTime = Date.now() - startTime.getTime()
      if (elapsedTime < 50 && currentURL && !resultSet.has(currentURL)) {
        resultSet.add(currentURL)
      } else {
        scriptQueue.postpone()
      }
    } else {
      scriptQueue.postpone()
    }

    const nextURL = scriptQueue.current()
    return nextURL ? [nextURL, calculateDelay()] : undefined
  }

  const executionHistory = []

  const execute = scriptURL => {
    const startTime = new Date()

    return executeScriptFunction(scriptURL)
      .then(result => {
        executionHistory.push({
          url: scriptURL,
          status: 'success',
          startedAt: startTime,
          finishedAt: new Date()
        })
        if (onSuccess) onSuccess(scriptURL)
        return result
      })
      .catch(error => {
        executionHistory.push({
          url: scriptURL,
          status: 'failed',
          startedAt: startTime,
          finishedAt: new Date(),
          error
        })

        if (onError) onError(scriptURL, error)

        if (executionHistory.length >= maxRetries || scriptQueue.isEmpty()) {
          throw new Error(`Execution failed: ${error.message}`)
        }

        const retryInfo = retryLogic(startTime, error)
        if (!retryInfo) throw error

        const [nextScriptURL, delayTime] = retryInfo
        return new Promise(resolve => setTimeout(resolve, delayTime)).then(() => execute(nextScriptURL))
      })
  }

  const initialURL = scriptQueue.current()
  if (!initialURL) {
    return Promise.reject(new TypeError('The list of script URL patterns is empty'))
  }

  return execute(initialURL).then(result => [result, executionHistory])
}
