import { getState, setState } from './storage'
import { CONSTANTS } from '../constants'
import { log } from './logger'
import { filterObject, now } from './helpers'

/**
 * Retrieve the current session from storage.
 * @returns {Object} - Session data object.
 *
 * @example
 * {
 *    cid: customer ID
 *    status: KNOWN | UNKNOWN | EXPIRED
 *    statusAt: Last status update timestamp
 *    authInitAt: Last auth initiation timestamp
 *    authAt: Last auth callback timestamp
 *    authViewAt: Last auth view timestamp
 *    expiredAt: Timestamp for explicit logout
 *    initAt: Timestamp when session was created
 * }
 */
export function getSession() {
  return getState(CONSTANTS.STORAGE_KEYS.SESSION) || {}
}

/**
 * Update the session data and save it to storage.
 * @param {Object} updates - The updates to apply to the session.
 * @param {Object|null} [updates.params] - Parameters to update or remove.
 * @returns {Object} - The updated session data.
 */
export function updateSession({ params, ...updates }) {
  // Handle parameters: remove or update them
  if (typeof params !== 'undefined') {
    const existingParams = getState(CONSTANTS.STORAGE_KEYS.PARAMS)
    log(CONSTANTS.LOG_MESSAGES.UPDATE_PARAMS, params, existingParams)

    const filteredParams = filterObject(
      params,
      CONSTANTS.PARAMS.TOKEN_KEYS,
      true
    );
    setState(CONSTANTS.STORAGE_KEYS.PARAMS, filteredParams)
  }

  // Get the current session
  const currentSession = getSession()

  // Add initialization timestamp if not already present
  if (!currentSession.initAt) {
    updates.initAt = now()
  }

  // Merge the updates into the current session
  const updatedSession = {
    ...currentSession,
    ...updates
  }

  // Save the updated session to storage
  setState(CONSTANTS.STORAGE_KEYS.SESSION, updatedSession)

  log(CONSTANTS.LOG_MESSAGES.UPDATE_SESSION, updates, updatedSession, currentSession)

  return updatedSession
}

export function expireSession() {
  log(CONSTANTS.LOG_MESSAGES.EXPIRED_SCENARIO)

  return updateSession({
    sid: null,
    status: CONSTANTS.STATUS_KEYS.EXPIRED,
    statusAt: now(),
    expiredAt: now()
  })
}
