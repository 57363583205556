import { log, error } from './utils/logger'
import { CONSTANTS } from './constants'
import { getSession, updateSession, expireSession } from './utils/session'
import {
  removeTokensFromCurrentLocation,
  getQueryParam,
  compactObject,
  now,
  daysAgo,
  redirectAway
} from './utils/helpers'
import { apiAuthResume, initiateAuthFlow } from './utils/api'

/**
 * Boot Session Initialization
 */
export async function bootSession(SIGNED_IN, CURRENT_PROFILE, IS_CLICK, CURRENT_PARAMS, CURRENT_URL) {
  try {
    log(CONSTANTS.LOG_MESSAGES.TIME_TO_CRUISE)

    let session = getSession()

    // Remove proprietary query string values right away
    removeTokensFromCurrentLocation()

    // Handle already signed-in scenarios
    if (SIGNED_IN) {
      let redirectAwayUrl = getQueryParam(CONSTANTS.AUTH_KEYS.REDIRECT)

      // Check for missing session ID, mismatched customer ID, or click parameters
      if (!session.sid || (session.cid && session.cid !== CURRENT_PROFILE.id) || IS_CLICK) {
        const authResumeParams = compactObject({
          ...(IS_CLICK ? CURRENT_PARAMS : getSession(CONSTANTS.STORAGE_KEYS.PARAMS)),
          url: encodeURIComponent(CURRENT_URL.href)
        })

        try {
          const { sessionId, redirectTo } = await apiAuthResume(authResumeParams, session.sid)

          updateSession({
            sid: sessionId,
            params: authResumeParams
          })

          redirectAwayUrl = redirectTo
        } catch (err) {
          error(CONSTANTS.MESSAGES.AUTH_RESUME_ERROR, err)
        }
      }

      session = updateSession({
        cid: CURRENT_PROFILE.id,
        status: CONSTANTS.STATUS_KEYS.KNOWN,
        statusAt: now(),
        authViewAt: now(),
        expiredAt: null
      })

      if (redirectAwayUrl) {
        redirectAway(redirectAwayUrl)
      }

      return
    }

    // Handle session expiration scenarios
    const authViewDays = daysAgo(session.authViewAt)
    if (authViewDays > CONSTANTS.TIMEOUTS.EXPIRATION_DAYS) {
      log(CONSTANTS.LOG_MESSAGES.EXPIRED_30_DAYS_SCENARIO, authViewDays)
      session = expireSession()
    }

    // Handle different session statuses
    if (session.status === CONSTANTS.STATUS_KEYS.KNOWN) {
      const isLogout = session.authViewAt && now() - session.authViewAt < CONSTANTS.TIMEOUTS.LOGOUT_WINDOW

      if (isLogout) {
        log(CONSTANTS.LOG_MESSAGES.EXPIRED_LOGOUT_SCENARIO)
        session = expireSession()
      } else {
        log(CONSTANTS.LOG_MESSAGES.UNKNOWN_SCENARIO)
        session = updateSession({
          status: CONSTANTS.STATUS_KEYS.UNKNOWN,
          statusAt: now()
        })
      }
    } else {
      const status = session.status || CONSTANTS.STATUS_KEYS.UNKNOWN

      log(`${status} ${CONSTANTS.LOG_MESSAGES.AUTH_SCENARIO_CONITNUED}`)
      session = updateSession({
        status,
        statusAt: now()
      })
    }

    log(CONSTANTS.LOG_MESSAGES.AUTH_INITIATED)

    // Handle authentication flow
    await initiateAuthFlow(CURRENT_PARAMS).then(initiated => {
      if (initiated) {
        return initiated
      }

      const { sid, status } = session

      if (!sid) {
        log(CONSTANTS.LOG_MESSAGES.CACHED_AUTH_FALLBACK_SKIPPED_NO_SESSION)
        return false
      }

      log(CONSTANTS.LOG_MESSAGES.CACHED_AUTH_FALLBACK_INITIATED)

      const authFlowParams = compactObject(IS_CLICK ? CURRENT_PARAMS : getSession(CONSTANTS.STORAGE_KEYS.PARAMS))

      return initiateAuthFlow(authFlowParams, sid)
    })
  } catch (err) {
    error(CONSTANTS.MESSAGES.AUTH_CALLBACK_ERROR, err)
  }
}
