import { executeWithRetry } from './scriptexecuter'

/**
 * Loads a list of external scripts with retry and backoff support.
 *
 * @param {Array<string>} scriptURLs - List of script URLs to load.
 * @param {Object} options - Configuration options for retries and callbacks.
 * @param {number} [options.maxRetries=3] - Maximum number of retries for each script.
 * @param {number} [options.maxDelay=1000] - Base delay in milliseconds for retries.
 * @param {number} [options.retryLimit=3000] - Maximum retry delay in milliseconds.
 * @param {Function} [options.onSuccess] - Callback executed when a script is loaded successfully.
 * @param {Function} [options.onError] - Callback executed when a script fails to load.
 * @returns {Promise<Array>} - Resolves with execution history after processing all scripts.
 */
export async function loadScripts(scriptURLs, options = {}) {
  const { maxRetries = 3, maxDelay = 1000, retryLimit = 3000, onSuccess = null, onError = null } = options

  /**
   * Function to load a single script into the DOM.
   *
   * @param {string} url - The script URL to load.
   * @returns {Promise<string>} - Resolves when the script is loaded successfully.
   */
  const loadScript = url => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = url
      script.async = true

      script.onload = () => resolve(url) // Successfully loaded
      script.onerror = () => reject(new Error(`Script load failed: ${url}`))

      document.head.appendChild(script) // Append script to the DOM
    })
  }

  try {
    // Execute the script loading logic with retries
    const [result, executionHistory] = await executeWithRetry(scriptURLs, loadScript, {
      maxRetries,
      maxDelay,
      retryLimit
    })

    return executionHistory
  } catch (error) {}
}
