import React, { useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'

import { H1, P } from 'src/components/shared/text'
import { SecondaryButton, PrimaryButton } from 'src/components/shared/buttons'
import { colors, fonts } from 'src/styles/variables'
import { media } from 'src/styles/util'

import Pumpkin from 'src/assets/img/homepage/pumpkin.svg'
import New from 'src/assets/img/homepage/new.svg'

const Section = styled.section`
  position: relative;
  background-color: ${colors.black};
  display: grid;
  place-items: center;
  overflow: hidden;
  ${media.mobile`
    padding-top: 60px;
  `}
  ${media.tablet`
    min-height: 100%;
  `}
  ${media.desktop`
    min-height: 45vh;
  `}
`

const Grid = styled.div`
  width: 100%;
  margin: 0;

  display: grid;
  align-items: center;
  height: 100%;
  justify-content: center;

  padding: 0;

  ${media.mobile`
  grid-template-columns: 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 1fr;

  `}
  ${media.desktop`
    max-width: 100%;
    grid-template-columns: 1fr;
  `}
`

const TextContainer = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  text-align: center;
  grid-gap: 20px;

  ${media.mobile`
    padding: 40px 25px;
    order: 2;
    background-color: ${colors.orange};
    grid-gap: 5px;

    img {
      position: absolute;
      z-index: 0;
      width: 100px;

      &.pumpkin-one {
        bottom: -23px;
        right: -18px;
      }
      &.pumpkin-two {
        top: 70%;
        left: 19%;
      }

      &.pumpkin-three {
        top: 130px;
        right: -45px;
        transform: rotate(-41deg);
      }
    }
  `}

  ${media.desktop`
    max-width: 100%;
    padding: 100px 50px;
    position: relative;
    transform: none;
    background-image: none;
    background-color: ${colors.orange};
    order: 1;

    img {
      position: absolute;
      z-index: 0;
      width: 450px;
      display: block;
      &.pumpkin-one {
        bottom: -30%;
        right: -10%;
      }
      &.pumpkin-two {
        top: 30%;
        left: -10%;
      }
      &.pumpkin-three {
        display: none;
      }
    }

  `}
`

const StyledH1 = styled(H1)`
  color: ${colors.orange};

  ${media.mobile`
    font-size: 69px;
    line-height: 1;
  `}
  ${media.tablet`
    font-size: 137px;
    line-height: 1;
  `}
  ${media.desktop`
    font-size: 137px;
    line-height: 1;
    text-shadow: 0px 1px 1px #018069;
  `}
`

const StyledH2 = styled(H1)`
  color: ${colors.white};
  position: relative;
  text-shadow: 0px 3px 1px #018069;

  ${media.mobile`
    font-size: 25px;
    line-height: 1;
    max-width: 150px;
    img {
      display: none;
    }
  `}
  ${media.tablet`
    font-size: 72px;
    line-height: 1;
  `}
  ${media.desktop`
    font-size: 85px;
    line-height: 1;
    justify-self: center;
    max-width: 800px;
    z-index: 1;
  `}
`

const StyledP = styled(P)`
  font-family: ${fonts.monosten.normal};
  text-transform: uppercase;
  color: ${colors.red};
  margin-bottom: 50px;

  ${media.mobile`
    font-size: 14px;
    line-height: 1;
    display: none;
  `}
  ${media.tablet`
    font-size: 20px;
    line-height: 1;
    display: block;
  `}
  ${media.desktop`
    font-size: 25px;
    line-height: 1;
    display: block;
  `}
`

const SubText = styled(P)`
  color: ${colors.white};
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  z-index: 1;
  ${media.mobile`
    font-size: 12px;
    line-height: 1;
    margin: 10px 0 20px 0;
  `}
  ${media.tablet`
    font-size: 38px;
    line-height: 1;
  `}
  ${media.desktop`
    font-size: 38px;
    line-height: 1.2;
    margin: 20px 0 60px;
    justify-self: center;
  `}
  span {
    color: ${colors.orange};
  }
`

const LifestyleImg = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  z-index: 2;

  .banner__desktop {
    ${media.mobile`
      display: none;
    `}
    ${media.tablet`
      display: block;
    `}
    ${media.desktop`
      display: block;
    `}
  }

  .banner__mobile {
    ${media.mobile`
      display: block;
    `}
    ${media.tablet`
      display: none;
    `}
    ${media.desktop`
      display: none;
    `}
  }
`

const ButtonGrid = styled.div`
  position: relative;

  display: grid;
  place-items: center;
  grid-gap: 15px;
  z-index: 1;

  ${media.mobile`
    grid-gap: 17px;
    align-self: center;

    & button {
      min-width: 125px;
      padding: 5px 20px;
      & p {
        font-size: 14px;
      }

      & svg {
        width: 14px;
        height: 14px;
        margin-bottom: -4px;
      }
    }
  `}

  ${media.tablet`
    grid-gap: 17px;
  `}
  ${media.desktop`
    grid-template-columns: auto auto;
    margin-top: 25px;

    & button {
      min-width: 340px;
      max-height: 64px;
      padding: 12px 20px;
      & p {
        font-size: 26px;
      }
    }

  `}
`

const query = graphql`
  query {
    lifestyle: file(relativePath: { regex: "/homepage/bone_desktop.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 2880)
      }
    }
    lifestyleMobile: file(relativePath: { regex: "/homepage/bone_mobile.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 860)
      }
    }
  }
`

const Jumbotron = () => {
  const { lifestyle, lifestyleMobile } = useStaticQuery(query)

  const handleClick = (destination: string) => {
    navigate(destination)
  }

  return (
    <Section>
      <Grid>
        <a href="/products">
          <LifestyleImg className="jumbotron__lifestyle">
            <GatsbyImage
              image={getImage(lifestyle)}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              className={'banner__desktop'}
            />
            <GatsbyImage
              image={getImage(lifestyleMobile)}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover', objectPosition: 'center bottom' }}
              className={'banner__mobile'}
            />
          </LifestyleImg>
        </a>
      </Grid>
    </Section>
  )
}

export default Jumbotron
