import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Accessories from 'src/components/OurProducts/Accessories'
import SEO from 'src/components/shared/SEO'
import Category from 'src/components/Products/category'
import AccessoriesBg from '../../assets/img/accessories/accessories-bg.png'

const SEO_CONFIG = {
  title: 'Accessories | Finn',
  description:
    'Shop all the products that Finn has to offer. We formulate pet supplements that combine trusted research and modern wellness. Get trusted nutrition with Finn.',
  keywords: ['Accessories']
}

const ProductsPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <Category title="Accessories" image={AccessoriesBg}>
          <Accessories />
        </Category>
      </Layout>
    </>
  )
}

export default ProductsPage
