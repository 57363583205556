import { log, error } from './logger'
import { CONSTANTS } from '../constants'
import { post } from './network' // Reusable POST function
import { updateSession } from './session'
import {
  createResponseError,
  now,
  getQueryParam,
  redirectAway,
  anyParamExists,
  getAnchorTag,
  setAuthInProgress,
  setRedirectInProgress
} from './helpers'

/**
 * Resume Authentication Flow
 * @param {Object} jsonData - Data payload for the auth resume request.
 * @param {string|null} [sessionId=null] - Optional session ID.
 * @returns {Object} - Payload data from the API response.
 * @throws {Error} - Throws an error for failed requests.
 */
export async function apiAuthResume(jsonData, sessionId = null) {
  setAuthInProgress(true)
  let response

  try {
    response = await post(CONSTANTS.API_ENDPOINTS.CUSTOMER_RESUME, jsonData, sessionId)
  } catch (e) {
    error(CONSTANTS.MESSAGES.AUTH_RESUME_ERROR, e)
    throw e
  }

  const payload = await response.json().catch(e => {
    error(CONSTANTS.MESSAGES.JSON_PARSE_ERROR, e)
    return { message: e.message }
  })

  log(CONSTANTS.LOG_MESSAGES.AUTH_RESPONSE_PAYLOAD, payload)

  if (!response.ok) {
    throw createResponseError(payload?.message || response.statusText, response, payload)
  }

  if (!payload?.data) {
    throw createResponseError(CONSTANTS.MESSAGES.AUTH_RESUME_SESSION_ERROR, response, payload)
  }

  log(CONSTANTS.LOG_MESSAGES.AUTH_DATA, payload.data, payload.message)

  return payload.data
}

/**
 * Initiate Authentication Flow
 * @param {Object} params - Parameters for initiating authentication.
 * @param {string|null} [sessionId=null] - Optional session ID.
 * @returns {boolean} - True if the auth flow was initiated, otherwise false.
 */
export async function initiateAuthFlow(params, sessionId = null) {
  if (!isAuthRequest(params) && !sessionId) {
    return false
  }

  log(CONSTANTS.LOG_MESSAGES.AUTH_INITIATED, params, sessionId)

  let session = updateSession({ authInitAt: now() })

  try {
    const response = await apiAuth({ ...params, url: encodeURIComponent(window.location.href) }, sessionId)

    const { redirectTo, sessionId: newSessionId, authEnabled } = response

    log(CONSTANTS.LOG_MESSAGES.AUTH_SUCCESS_REDIRECT, redirectTo, newSessionId)

    session = updateSession({
      sid: newSessionId,
      status: CONSTANTS.STATUS_KEYS.KNOWN,
      statusAt: now(),
      authAt: now(),
      params
    })

    if (authEnabled) {
      setAuthInProgress(true)
      setRedirectInProgress()
      redirectAway(redirectTo)
    }
  } catch (e) {
    error(CONSTANTS.MESSAGES.AUTH_CALLBACK_ERROR, e)

    log(
      CONSTANTS.LOG_MESSAGES.AUTH_AMBIGUOUS_ERROR,
      e.message || e,
      e.response,
      getQueryParam(CONSTANTS.AUTH_KEYS.REDIRECT)
    )

    if (e.response?.status >= 400) {
      session = updateSession({
        status: CONSTANTS.STATUS_KEYS.INVALID,
        statusAt: now(),
        params
      })
    }

    redirectAway(getQueryParam(CONSTANTS.AUTH_KEYS.REDIRECT))
  }

  return true
}

/**
 * Check if the request is an authentication request
 * @param {Object} params - Parameters to check for auth-related tokens.
 * @returns {boolean} - True if auth parameters exist, otherwise false.
 */
function isAuthRequest(params) {
  const result = anyParamExists(CONSTANTS.PARAMS.TOKEN_KEYS, params)
  log(CONSTANTS.LOG_MESSAGES.IS_AUTH_REQUEST, result)
  return result
}

/**
 * Perform API Authentication Request
 * @param {Object} jsonData - The JSON payload for the API request.
 * @param {string|null} [sessionId=null] - Optional session ID for authentication.
 * @returns {Object} - Response containing redirectTo and sessionId.
 * @throws {Error} - Throws an error if authentication fails.
 */
export async function apiAuth(jsonData, sessionId = null) {
  setAuthInProgress(true)
  let response

  try {
    response = await post(CONSTANTS.API_ENDPOINTS.CUSTOMER_AUTH, jsonData, sessionId)
  } catch (e) {
    error(CONSTANTS.MESSAGES.AUTH_ENDPOINT_ERROR, e)
    throw e
  }

  const payload = await response.json().catch(e => {
    error(CONSTANTS.MESSAGES.JSON_PARSE_ERROR, e)
    return { message: e.message }
  })

  log(CONSTANTS.LOG_MESSAGES.AUTH_RESPONSE_DATA, payload)

  if (!response.ok) {
    throw createResponseError(payload?.message || response.statusText, response, payload)
  }

  if (!payload?.data) {
    throw createResponseError(CONSTANTS.MESSAGES.AUTH_REDIRECT_ERROR, response, payload)
  }

  const authUrl = payload.data.redirectTo || payload.data
  const authEnabled = !String(payload.message).toLowerCase().includes('anonymous')

  sessionId = payload.data.sessionId

  log(CONSTANTS.LOG_MESSAGES.AUTH_URL_DETAILS, authUrl, authEnabled, sessionId, payload.message)

  if (!authEnabled || !authUrl.startsWith(window.location.origin)) {
    return { redirectTo: authUrl, sessionId, authEnabled }
  }

  const anchorTag = getAnchorTag(authUrl)

  return fetch(authUrl, {
    credentials: 'include',
    redirect: 'follow',
    method: 'GET',
    headers: CONSTANTS.API_HEADERS.DEFAULT
  }).then(({ url }) => {
    const redirectTo = anchorTag ? `${url}${anchorTag}` : url

    return { redirectTo, sessionId, authEnabled }
  })
}
